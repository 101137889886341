import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
//import './layout.css'

import Header from './header.js'
import Break from './Break.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner)

const meta = {
  'en-gb': [
    { name: 'description', content:
  'Psychotherapy and Counselling services in Mill Road, Cambridge CB1. Evening sessions are available. Book your initial appointment now using the automated booking form on this website. Get professional psychological help to set yourself free from your struggles.'
    },
    { name: 'keywords', content: 'psychotherapy, counselling, psychological help, mental support, Cambridge, depression, anxiety' },
  ],
  'ru': [
    { name: 'description', content: 'Психотерапия и психологическая помощь в Кембридже, Англия и онлайн.' },
    { name: 'keywords', content: 'психотерапия, психологическое консультирование, психологическая поддержка, психологическая помощь, Кембридж, депрессия, стресс' },
  ],
}

const localBusiness = {
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "@id": "https://duraj.space",
  "name": "duraj.space",
  "description": "Psychotherapy and Counselling services in Mill Road, Cambridge.",
  "image": [
    "https://duraj.space/static/5d928b54f30febb81dad3451ff9e05ea/9cfa0/2f9ec3aca1fc1b09f90f378a203f1c12fa591e02_romsey-terrace-s-35.jpg",
    "https://durajspace.cdn.prismic.io/durajspace%2Fbefcba1f-211e-4283-9dad-c9664290d748_couch.svg"
  ],
  "address" :{
    "@type": "PostalAddress",
    "streetAddress": "8B Romsey Terrace",
    "addressLocality": "Cambridge",
    "addressRegion": "Cambridgeshire",
    "postalCode": "CB1 3NH",
    "addressCountry": "UK"
  },
  "geo":{
    "@type": "GeoCoordinates",
    "latitude": 52.196763,
    "longitude": 0.146877
  },
  "potentialAction": {
    "@type": "ReserveAction",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "https://duraj.space/#book",
      "inLanguage": "en-GB",
      "actionPlatform": [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/IOSPlatform",
        "http://schema.org/AndroidPlatform"
      ]
    },
    "result": {
      "@type": "Reservation",
      "name": "Book Initial Appointment"
    }
  },
  "priceRange": "£40 - £60"
}

const Layout = ({ title, lang, children }) => (
  <>
  <Helmet
    title={title}
    meta={meta[lang]}
  >
    <html lang={lang === 'en-gb' ? 'en' : lang} />
    <script type="application/ld+json">{JSON.stringify(localBusiness)}</script>
  </Helmet>
  <div
    style={{
      margin: '0 auto',
      maxWidth: 960,
      padding: '0px 1.0875rem 1.45rem',
      paddingTop: '1rem',
    }}
  >
    <Header lang={lang} />
    <Break />
    {children}
  </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
